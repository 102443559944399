@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	outline: none;
}

:root {
	--background: 0 0% 100%;
	--foreground: 222.2 84% 4.9%;
	--card: 0 0% 100%;
	--card-foreground: 222.2 84% 4.9%;
	--popover: 0 0% 100%;
	--popover-foreground: 222.2 84% 4.9%;
	--primary: 222.2 47.4% 11.2%;
	--primary-foreground: 210 40% 98%;
	--secondary: 210 40% 96.1%;
	--secondary-foreground: 222.2 47.4% 11.2%;
	--muted: 210 40% 96.1%;
	--accent: 210 40% 96.1%;
	--accent-800: 210 40% 94.1%;
	--accent-foreground: 222.2 47.4% 11.2%;
	--destructive: 0 84.2% 60.2%;
	--destructive-foreground: 210 40% 98%;
	--border: 214.3 31.8% 91.4%;
	--input: 214.3 31.8% 91.4%;
	--ring: 222.2 84% 4.9%;
	--radius: 0.5rem;

	--background-0: #fff;
	--background-50: #f8fafc;
	--background-100: #f1f5f9;
	--background-200: #e2e8f0;
	--background-300: #cbd5e1;
	--background-400: #94a3b8;
	--background-500: #64748b;
	--background-600: #475569;
	--background-700: #334155;
	--background-800: #1e293b;
	--background-900: #0f172a;
	--background-950: #020617;

	--foreground-50: #f8fafc;
	--foreground-100: #f1f5f9;
	--foreground-200: #e2e8f0;
	--foreground-300: #cbd5e1;
	--foreground-400: #94a3b8;
	--foreground-500: #64748b;
	--foreground-600: #475569;
	--foreground-700: #334155;
	--foreground-800: #1e293b;
	--foreground-900: #0f172a;
	--foreground-950: #020617;

	--muted-background: #f1f5f9;

	--ng-icon__stroke-width: 2.2;
	// --font-size: 12.4px;
}

.dark {
	--background: 0.2 84% 4.9%;
	--foreground: 210 40% 98%;
	--card: 222.2 84% 4.9%;
	--card-foreground: 210 40% 98%;
	--popover: 222.2 84% 4.9%;
	--popover-foreground: 210 40% 98%;
	--primary: 210 40% 98%;
	--primary-foreground: 222.2 47.4% 11.2%;
	--secondary: 217.2 32.6% 17.5%;
	--secondary-foreground: 210 40% 98%;
	--muted: 217.2 32.6% 17.5%;
	--muted-foreground: 215 20.2% 65.1%;
	--accent: 217.2 32.6% 17.5%;
	--accent-800: 217.2 32.6% 15.5%;
	--accent-foreground: 210 40% 98%;
	--destructive: 0 62.8% 30.6%;
	--destructive-foreground: 210 40% 98%;
	--border: 217.2 32.6% 17.5%;
	--input: 217.2 32.6% 17.5%;
	--ring: 212.7 26.8% 83.9%;

	--foreground-50: #020617;
	--foreground-100: #0f172a;
	--foreground-200: #1e293b;
	--foreground-300: #334155;
	--foreground-400: #475569;
	--foreground-500: #64748b;
	--foreground-600: #A6A6A6;
	--foreground-700: #cbd5e1;
	--foreground-800: #e2e8f0;
	--foreground-900: #f1f5f9;
	--foreground-950: #f8fafc;

	--background: #181818;
	--background-0: var(--background);
	--background-50: color-mix(in srgb, var(--background) 98%, white);
	--background-100: color-mix(in srgb, var(--background) 96%, white);
	--background-200: color-mix(in srgb, var(--background) 94%, white);
	--background-300: color-mix(in srgb, var(--background) 92%, white);
	--background-400: color-mix(in srgb, var(--background) 90%, white);
	--background-500: color-mix(in srgb, var(--background) 88%, white);
	--background-600: color-mix(in srgb, var(--background) 86%, white);
	--background-700: color-mix(in srgb, var(--background) 84%, white);
	--background-800: color-mix(in srgb, var(--background) 82%, white);
	--background-900: color-mix(in srgb, var(--background) 80%, white);
	--background-950: color-mix(in srgb, var(--background) 78%, white);

	--muted-background: var(--background-200);
}

html,
body {
	height: 100%;
	margin: 0;
	font: 400 var(--font-size)/18px system-ui;
}
